import { useState, useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import whiteSpinner from "../assets/img/spin.svg";
import { toast } from "react-toastify";

const DonateSection = () => {
  const [amount, setAmount] = useState();
  const {
    handleDonate,
    donatepending,
    donateText,
    approveBusd,
    account
  } = useContext(BlockchainContext);

  const donate = () => {
    if (new Date().getTime() > 1652554800000) {
      if (donateText === "Enable") {
        approveBusd(account);
      } else {
        if (Number(amount) > 0) {
          handleDonate(amount);
        } else {
          toast.error("Enter valid amount", {
            position: "bottom-right",
          });
        }
      }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };


  return (
    <div className="py-16 sm:py-24">
      <div className="relative sm:py-16">
        {/* <div aria-hidden="true" className="hidden sm:block">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
        </div> */}
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative rounded-2xl px-6 py-10 bg-slate-800 overflow-hidden shadow-xl sm:px-12 sm:py-20 donate">
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  Donate Us
                </h2>
                <p className="mt-6 mx-auto max-w-2xl text-lg text-indigo-200">
                  Help & Support us By Donating
                </p>
              </div>
              <div className="mt-12 sm:mx-auto sm:max-w-lg sm:flex donate-div">
                <div className="min-w-0 flex-1">
                  <input
                    className="input-stake"
                    placeholder="USDT"
                    type="text"
                    value={amount}
                    step={0.00001}
                    onChange={(event) => {
                      if (
                        event.target.value >= 0 ||
                        event.target.value === ""
                      ) {
                        setAmount(event.target.value);
                      }
                    }}
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    onClick={() => donate()}
                    className="button-100 donate-btn"
                  >
                    {donatepending ? (
                      <>
                        {"PENDING..."}
                      </>
                    ) : (
                      <>{"DONATE"}</>
                    )}

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DonateSection