import { useState, useEffect } from "react";
import axios from "axios";
const FooterSection = () => {
  const [links, setLinks] = useState();

  useEffect(() => {
    fetchLinks();
  }, []);

  const fetchLinks = async () => {
    const response = await axios.get("/config.json");
    setLinks(response.data.urls);
  };

  return (
    <footer>
      <div className="mt-12 border-t border-violeta pt-8 pb-12 footer-margin" style={{backgroundColor: "#000942"}}>
        <p className="text-base text-verdeClaro text-center">
          © 2022 Ninja Bot Trader
        </p>
        <div className="footer-top d-flex flex-wrap align-items-center justify-content-center gap-5 p-4 footer" >
          <a target="_blank" href="https://t.me/BSCROBOTIC_GROUP">
            <img
              src="/assets/img/telegram.png"
              alt="telegram"
              className="footer-bottom-img social"
            />
          </a>
          <a target="_blank" href="https://shield-crypto.pages.dev/ninja">
            <img
              src="/assets/img/favicon_1.png"
              alt="shield crypto"
              className="footer-bottom-img haze"
            />
          </a>
          <a
            className="footer-bsc"
            target="_blank"
            href={process.env.REACT_APP_BNB_FACTOR_CONRACT}
          >
            <img
              src="/assets/img/bscscan.png"
              alt="BSC Scan"
              className="footer-bottom-img bsc"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
