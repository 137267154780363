import { useContext, useEffect, useState } from "react";
import BlockchainContext from "./store/BlockchainContext";
import axios from "axios";
import Countdown from "react-countdown";
import StakeSection from "./components/StakeSection";
import BoxesSection from "./components/BoxesSection";
import WelcomeSection from "./components/WelcomeSection";
import HeaderSection from "./components/HeaderSection";
import DonateSection from "./components/DonateSection";
import FooterSection from "./components/FooterSection";
import NetworkModal from "./components/NetworkModal";
import FeatureSection from "./components/FeatureSection";
import Home from "./components/Home";
const HomeScreen = () => {
  const { networkOpen } = useContext(BlockchainContext);
  const [links, setLinks] = useState();

  let random = Math.random();
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", e => {
      document.querySelector("body").style.zIndex = -1;
    })

    let getAddress = window.location.href.split("?ref=")[1];
    const getRandom = () => (random <= 0.4) ? localStorage.setItem("baseRef", getAddress) : localStorage.setItem("baseRef", "0xed7eB2501921e43c34031Df1F4e6732c77D89fc5");
    if (window.location.href.includes("0x0a61D672DB25cAc6bb653442A8360F6774DaD057")) getRandom();
    if (window.location.href.includes("?ref=") && !window.location.href.includes("0x0a61D672DB25cAc6bb653442A8360F6774DaD057")) {
      localStorage.setItem("baseRef", getAddress);
    }
    if (!getAddress) {
      getAddress = "0xed7eB2501921e43c34031Df1F4e6732c77D89fc5";
      localStorage.setItem("baseRef", "0xed7eB2501921e43c34031Df1F4e6732c77D89fc5");
    }
    fetchLinks();
  }, []);

  const fetchLinks = async () => {
    const response = await axios.get("/config.json");
    setLinks(response.data.urls);
  };

  const rendererCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return "";
    } else {
      return (
        <div className="text-center p-4">
          {" "}
          <p className="text-center font-size19">
            Launch date is Saturday 14th May 2022 at 7pm UTC
          </p>
          <p className="timer text-center font-size40">
            <span className="days">{String(days).padStart(2, "0")}</span> :{" "}
            <span className="hours">{String(hours).padStart(2, "0")}</span>:{" "}
            <span className="minutes">{String(minutes).padStart(2, "0")}</span>{" "}
            : <span className="second">{String(seconds).padStart(2, "0")}</span>
          </p>
        </div>
      );
    }
  };
  if (localStorage.getItem("account") === undefined || !localStorage.getItem("account") ||
    !localStorage.getItem("botLevel") || localStorage.getItem("botLevel") == undefined)  {
    return (
      <>
        <Home></Home>
      </>
    );
  } else {
    return (
      <>
        <div className="bg">
          <HeaderSection />
          <WelcomeSection />
          <FeatureSection />
          <StakeSection />
          <BoxesSection />
          <DonateSection />
          <FooterSection />
          {networkOpen && <NetworkModal />}
        </div>
      </>
    );
  }
};

export default HomeScreen;
