import { ReactComponent as CheckIcon } from "../assets/img/Chield_check_fill.svg";

const FeatureSection = () => {
  return (
    <main
      id="dashboard"
      className="pt-32 mx-auto max-w-7xl px-4 sm:px-6 pb-8"
      style={{ opacity: "1" }}
    >
      <div className="box-holder  space-evenly">
        <div className="feature_box">
          <img src="/assets/img/shuriken.png" className="feature-img" />
          <p className="feature_title">
            ANTI WHALE PROTECTION
          </p>
          <p className="feature_text">
            Smart Liquidity and Low Profits to Secure our <a target={"_blank"} href={process.env.REACT_APP_BNB_FACTOR_CONRACT} className="text-azul bold" style={{marginLeft: "1px"}}>Liquidity</a>
          </p>
        </div>
        <div className="feature_box">
          <img src="/assets/img/shuriken.png" className="feature-img" />
          <p className="feature_title">
            Scam protection
          </p>
          <p className="feature_text">
            Ninja Bot Trader is an Audited Project by <a target={"_blank"} href="https://shield-crypto.pages.dev/ninja" className="text-azul bold" style={{marginLeft: "1px"}}>Shield Crypto</a>
          </p>
        </div>
      </div>
    </main>
  );
};

export default FeatureSection;
