import { useState, useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import { walletAddressSlicer } from "../utils/util";
const HeaderSection = () => {
  const [open, setOpen] = useState(false);
  const { handleConnectToWallet, account, handleDisconnectWallet } =
    useContext(BlockchainContext);
  return (
    <>
      <div className="sticky top-0 z-40 w-full border-b border-gray-200 backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 bg-white/95 supports-backdrop-blur:bg-white/60 header">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1"></div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                className="menu-btn border border-slat hover:header-a-hovere-200 rounded-md p-2 inline-flex items-center justify-center text-black"
                id="headlessui-popover-button-1"
                type="button"
                aria-expanded="false"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">OPEN MENU</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-7">
              <a
                className="text-base font-medium text-white cursor-pointer bold hover:header-a-hover"
                href="#/"
              >
                HOME
              </a>
              <a
                target="_blank"
                href="https://shield-crypto.pages.dev/ninja"
                className="text-base text-white cursor-pointer bold hover:header-a-hover"
              >
                WHITEPAPER
              </a>
              <a
                href={process.env.REACT_APP_BNB_FACTOR_CONRACT}
                target="_blank"
                className="text-base text-white bold hover:header-a-hover"
              >
                CONTRACT
              </a>
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {account ? (
                <button
                  onClick={() => handleDisconnectWallet("wallet")}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-black bold connect br-5"
                >
                  {walletAddressSlicer(account)}
                </button>
              ) : (
                <button
                  onClick={() => handleConnectToWallet()}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-black bold connect br-5"
                >
                  Connect to Wallet
                </button>
              )}
            </div>
          </div>
        </div>
        {open && (
          <div
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            id="headlessui-popover-panel-2"
          >
            <div className="menu br-5 shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between menu-div">
                  <div>
                  </div>
                  <div className="-mr-2">
                    <button
                      className="x br-5 p-2 inline-flex items-center justify-center text-white focus:outline-none"
                      type="button"
                      tabindex="0"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <a
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                      href="#/"
                    >
                      <span className="ml-3 text-base bold text-black">
                        HOME
                      </span>
                    </a>
                    <a
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                      href={process.env.REACT_APP_BNB_FACTOR_CONRACT}
                    >
                      <span className="ml-3 text-base bold text-black">
                        INVEST
                      </span>
                    </a>
                    <a
                      href="/whitepaper.pdf"
                      target="_blank"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                    >
                      <span className="ml-3 text-base bold text-black">
                        WHITEPAPER
                      </span>
                    </a>
                    <a
                      href={process.env.REACT_APP_BNB_FACTOR_CONRACT}
                      target="_blank"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                    >
                      <span className="ml-3 text-base bold text-black">
                        CONTRACT
                      </span>
                    </a>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>
                  {account ? (
                    <button
                      onClick={() => handleDisconnectWallet("wallet")}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent br-5 text-base font-medium connect-mobile text-black bold"
                    >
                      {walletAddressSlicer(account)}
                    </button>
                  ) : (
                    <button
                      onClick={() => handleConnectToWallet()}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent br-5 text-base font-medium connect-mobile text-black bold"
                    >
                      Connect to wallet
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderSection;
