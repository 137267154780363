import BlockchainContext from "../store/BlockchainContext";
import { useContext, useEffect, useState } from "react";

import FooterSection from "./FooterSection";
import { walletAddressSlicer, numberWithCommas } from "../utils/util";
import { toast } from "react-toastify";

const Home = () => {
  const [amount = 35, setAmount] = useState();
  const { handleDonate, donatepending, donateText, approveBusd, array } =
    useContext(BlockchainContext);

  const donate = () => {
    if (new Date().getTime() > 1652554800000) {
      if (donateText === "Enable") {
        approveBusd(account);
      } else {
        if (Number(amount) > 0) {
          handleDonate(amount);
        } else {
          toast.error("Enter valid amount", {
            position: "bottom-right",
          });
        }
      }
    } else {
      toast.warn("Launch date is Saturday 14th May 2022 at 7pm UTC", {
        position: "bottom-right",
      });
    }
  };

  console.log(array);

  const [contract, web3, acc] = array;

  let final = 0;

  const _balance = async () => {
    if (!acc || acc === undefined) return;
    await contract.methods
      .users(acc)
      .call(
        (error, result) => (final = web3.utils.fromWei(result[0].toString()))
      );
    localStorage.setItem("balance", final);
  };

  const _getBotLevel = async () => {
    if (!acc || acc === undefined) return;
    await contract.methods
      .getMyBotLevel(acc)
      .call(
        (error, result) => (final = result)
      );
    localStorage.setItem("botLevel", final);
  };

  document.addEventListener("DOMContentLoaded", _getBotLevel()); 

  document.addEventListener("DOMContentLoaded", _balance());

  const [open, setOpen] = useState(false);
  const { handleConnectToWallet, account, handleDisconnectWallet } =
    useContext(BlockchainContext);
  return (
    <div className="minh-100">
      <div className="sticky top-0 z-40 w-full border-b border-gray-200 backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 bg-white/95 supports-backdrop-blur:bg-white/60 header">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1"></div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                className="menu-btn border border-slat hover:header-a-hovere-200 rounded-md p-2 inline-flex items-center justify-center text-black"
                id="headlessui-popover-button-1"
                type="button"
                aria-expanded="false"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">OPEN MENU</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-7">
              <a
                target="_blank"
                href="https://shield-crypto.pages.dev/ninja"
                className="text-base text-white cursor-pointer bold hover:header-a-hover"
              >
                WHITEPAPER
              </a>
              <a
                href={process.env.REACT_APP_BNB_FACTOR_CONRACT}
                target="_blank"
                className="text-base text-white bold hover:header-a-hover"
              >
                CONTRACT
              </a>
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {account ? (
                <button
                  onClick={() => handleDisconnectWallet("wallet")}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-black bold connect br-5"
                >
                  {walletAddressSlicer(account)}
                </button>
              ) : (
                <button
                  onClick={() => handleConnectToWallet()}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-black bold connect br-5"
                >
                  Connect to Wallet
                </button>
              )}
            </div>
          </div>
        </div>
        {open && (
          <div
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            id="headlessui-popover-panel-2"
          >
            <div className="menu br-5 shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between menu-div">
                  <div></div>
                  <div className="-mr-2">
                    <button
                      className="x br-5 p-2 inline-flex items-center justify-center text-white focus:outline-none"
                      type="button"
                      tabindex="0"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <a
                      href="/whitepaper.pdf"
                      target="_blank"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                    >
                      <span className="ml-3 text-base bold text-black">
                        WHITEPAPER
                      </span>
                    </a>
                    <a
                      href={process.env.REACT_APP_BNB_FACTOR_CONRACT}
                      target="_blank"
                      className="-m-3 p-3 flex items-center br-5 cursor-pointer a-menu"
                    >
                      <span className="ml-3 text-base bold text-black">
                        CONTRACT
                      </span>
                    </a>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>
                  {account ? (
                    <button
                      onClick={() => handleDisconnectWallet("wallet")}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent br-5 text-base font-medium connect-mobile text-black bold"
                    >
                      {walletAddressSlicer(account)}
                    </button>
                  ) : (
                    <button
                      onClick={() => handleConnectToWallet()}
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent br-5 text-base font-medium connect-mobile text-black bold"
                    >
                      Connect to wallet
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mas-grande">
        <h1
          className="text-white font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center white-none margint"
          style={{ marginTop: "0" }}
        >
          Start <span className="text-violeta">Earning</span> Daily{" "}
          <span className="text-azul">2% </span>to{" "}
          <span className="text-azul">3% </span> of <br></br>Your Investment in{" "}
          <span className="text-verdeClaro">USDT</span> with
        </h1>
        <img src="./assets/img/ninja.png" className="welcome-img"></img>
        <p className="mt-6 text-lg text-white text-center max-w-3xl mx-auto p-home">
          Invest your <span className="text-verdeClaro bold">USDT</span> and get
          daily profits with{" "}
          <span className="text-azul bold">Ninja Bot Trader!</span>
        </p>

        <div className="stake-bottom stake-bottom-home">
          <div className="stake-bottom-item stake-bottom-item-home">
            <h2 className="buythebot">Pay $35 to buy the bot</h2>
            <div className="">
              <div className="relative no-selection">
                <input
                  className="input-stake "
                  id="input-stake-home"
                  type="text"
                  value={amount}
                  step={0.00001}
                  disabled={true}
                  onChange={(event) => {
                    if (event.target.value >= 0 || event.target.value === "") {
                      setAmount(event.target.value); // Ningun error
                    }
                  }}
                />
                <b
                  className="input-current i-c"
                  style={{
                    bottom: "22px !important",
                    marginRight: "17px",
                    padding: 0,
                  }}
                >
                  USDT
                </b>
              </div>
            </div>
            <span
              id="input-stake-home-span"
              style={{ color: "black", textAlign: "center" }}
            >
              Invalid Amount
            </span>
            <div className="">
              <button
                type="submit"
                onClick={() => donate()}
                className="button-secondary invest"
              >
                {donatepending ? <>{"PENDING..."}</> : <>{"BUY"}</>}
              </button>
            </div>
            <a
              href="#"
              className="invest invest-stake-btn"
              style={{
                backgroundColor: "var(--violeta)",
                color: "var(--verdeClaro)",
                padding: "10px",
                height: "60px",
              }}
            >
              <span>Switch to BNB Ninja Website</span>
            </a>
          </div>
        </div>
      </div>

      <FooterSection></FooterSection>
    </div>
  );
};

export default Home;
