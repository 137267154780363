const WelcomeSection = () => {
  return (
    <div id="welcome" className="bg-slate-100">
      <div className="relative">
        <div className="relative container max-w-7xl mx-auto px-8 py-8 pt-0 lg:pt-4 welcome">
          <main className="lg:pt-2 pt-7 lg:pb-24 pb-40 welcome-sexo">
            <div className="grid gap-12 z-30 text-center">
              <div className="grid gap-8 flex justify-center">
                <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden padding-sexo">
                  <div className="mx-auto rounded-lg lg:px-8 lg:py-0 py-4">
                    <div className="relative max-w-5xl mx-auto pt-20 sm:pt-24 lg:pt-32 padding-sexo">
                    <h1 className="text-white font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center white-none margint">
                      Start <span className="text-violeta">Earning</span> Daily <span className="text-azul">2% </span>to <span className="text-azul">3% </span> of <br></br>Your Investment in <span className="text-verdeClaro">USDT</span> with
                    </h1>
                    <img src="./assets/img/ninja.png" className="welcome-img"></img>
                    <p className="mt-6 text-lg text-white text-center max-w-3xl mx-auto p-home">
                      Invest your <span className="text-verdeClaro bold">USDT</span> and get daily profits with <span className="text-azul bold">Ninja Bot Trader!</span>
                    </p>
                      <div className="mt-6 sm:mt-10 flex justify-center space-x-6 text-sm">
                        <a
                          className="invest cursor-pointer focus:outline-none text-black bold h-12 px-12 rounded-lg w-full flex items-center justify-center sm:w-auto text-base"
                          href="#dashboard"
                        >
                          INVEST
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;
